export const BET_TABLE = {
  firstRow: [
    { label: 3, color: "red" },
    { label: 6, color: "black" },
    { label: 9, color: "red" },
    { label: 12, color: "red" },
    { label: 15, color: "black" },
    { label: 18, color: "red" },
    { label: 21, color: "red" },
    { label: 24, color: "black" },
    { label: 27, color: "red" },
    { label: 30, color: "red" },
    { label: 33, color: "black" },
    { label: 36, color: "red" },
  ],
  secondRow: [
    { label: 2, color: "black" },
    { label: 5, color: "red" },
    { label: 8, color: "black" },
    { label: 11, color: "black" },
    { label: 14, color: "red" },
    { label: 17, color: "black" },
    { label: 20, color: "black" },
    { label: 23, color: "red" },
    { label: 26, color: "black" },
    { label: 29, color: "black" },
    { label: 32, color: "red" },
    { label: 35, color: "black" },
  ],
  thirdRow: [
    { label: 1, color: "red" },
    { label: 4, color: "black" },
    { label: 7, color: "red" },
    { label: 10, color: "red" },
    { label: 13, color: "black" },
    { label: 16, color: "red" },
    { label: 19, color: "red" },
    { label: 22, color: "black" },
    { label: 25, color: "red" },
    { label: 28, color: "red" },
    { label: 31, color: "black" },
    { label: 34, color: "red" },
  ],
};

//declaring here all the combinations, easier this way
export const twoByOneFirst = [3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36];
export const twoByOneSecond = [2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35];
export const twoByOneThird = [1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34];
export const firstTwelves = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export const secondTwelves = [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
export const thirdTwelves = [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36];
export const oneToEighteen = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
];
export const nineteenToThirtySix = [
  19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36,
];
export const black = [
  2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35,
];
export const red = [
  1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36,
];
export const even = [
  2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36,
];
export const odd = [
  1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35,
];

export const chipsTableData = [
  [
    ["00", 3],
    3,
    [3, 6],
    6,
    [6, 9],
    9,
    [9, 12],
    12,
    [12, 15],
    15,
    [15, 18],
    18,
    [18, 21],
    21,
    [21, 24],
    24,
    [24, 27],
    27,
    [27, 30],
    30,
    [30, 33],
    33,
    [33, 36],
    36,
  ],
  [
    ["00", 2, 3],
    [2, 3],
    [2, 3, 5, 6],
    [6, 5],
    [6, 9, 8, 5],
    [8, 9],
    [8, 9, 11, 12],
    [11, 12],
    [11, 12, 14, 15],
    [14, 15],
    [14, 15, 17, 18],
    [17, 18],
    [17, 18, 20, 21],
    [20, 21],
    [20, 21, 23, 24],
    [23, 24],
    [23, 24, 26, 27],
    [26, 27],
    [26, 27, 29, 30],
    [29, 30],
    [29, 30, 32, 33],
    [32, 33],
    [32, 33, 35, 36],
    [35, 36],
  ],
  [
    ["00", 0],
    ["00", 0, 2],
    2,
    [2, 5],
    5,
    [5, 8],
    8,
    [8, 11],
    11,
    [11, 14],
    14,
    [14, 17],
    17,
    [17, 20],
    20,
    [20, 23],
    23,
    [23, 26],
    26,
    [26, 29],
    29,
    [29, 32],
    32,
    [32, 35],
    35,
  ],
  [
    [0, 1, 2],
    [1, 2],
    [1, 2, 4, 5],
    [4.5],
    [4, 5, 7, 8],
    [7, 8],
    [7, 8, 10, 11],
    [10, 11],
    [10, 11, 13, 14],
    [13, 14],
    [13, 14, 16, 17],
    [16, 17],
    [16, 17, 19, 20],
    [19.2],
    [19, 20, 22, 23],
    [22, 23],
    [22, 23, 25, 26],
    [25, 26],
    [25, 26, 28, 29],
    [28, 29],
    [28, 29, 31, 32],
    [31, 32],
    [31, 32, 34, 35],
    [34, 35],
  ],
  [
    [0, 1],
    1,
    [1, 4],
    4,
    [4, 7],
    7,
    [7, 10],
    10,
    [10, 13],
    13,
    [13, 16],
    16,
    [16, 19],
    19,
    [19, 22],
    22,
    [22, 25],
    25,
    [25, 28],
    28,
    [28, 31],
    31,
    [31, 34],
    34,
  ],
  [
    ["00", 0, 1, 2, 3],
    [1, 2, 3],
    [1, 2, 3, 4, 5, 6],
    [4, 5, 6],
    [4, 5, 6, 7, 8, 9],
    [7, 8, 9],
    [7, 8, 9, 10, 11, 12],
    [10, 11, 12],
    [10, 11, 12, 13, 14, 15],
    [13, 14, 15],
    [13, 14, 15, 16, 17, 18],
    [16, 17, 18],
    [16, 17, 18, 19, 20, 21],
    [19, 20, 21],
    [19, 20, 21, 22, 23, 24],
    [22, 23, 24],
    [22, 23, 24, 25, 26, 27],
    [25, 26, 27],
    [25, 26, 27, 28, 29, 30],
    [28, 29, 30],
    [28, 29, 30, 31, 32, 33],
    [31, 32, 33],
    [31, 32, 33, 34, 35, 36],
    [34, 35, 36],
  ],
];
